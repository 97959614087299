import React from 'react'
import { Box, Container, Flex } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentForm from '@solid-ui-components/ContentForm'

const styles = {
  a: {
    marginLeft: '0',
    display: 'block',
    paddingTop: '0 !important'
  }
}

const ContentBlock01 = ({ content: { collection }, reverse }) => (
  <Container>
    <Flex
      sx={{
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `2/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: ['center', 'left']
        }}
      >
        {collection?.[0] && (
          <>
            <Box sx={{ textAlign: ['center', 'left'] }}>
              <ContentText content={collection[0]?.text} ml='0' />
            </Box>
            {collection[0]?.buttons && (
              <>
                <Divider space={3} />

                <ContentButtons
                  content={collection[0].buttons}
                  wrapperStyles={styles}
                />
              </>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          flexBasis: `3/5`,
          alignSelf: `center`,
          textAlign: [`center`, null, null, `left`]
        }}
      >
        {collection?.[1] && (
          <>
            <Box sx={{ textAlign: ['left'] }}>
              <ContentText content={collection[1]?.text} ml='0' />
            </Box>
            {collection[1]?.buttons && (
              <>
                <Divider space={3} />

                <ContentButtons content={collection[1].buttons} />
              </>
            )}
            {collection[1]?.form && (
              <ContentForm
                form={collection[1]?.form}
                id='ContentBlock01-form'
              />
            )}
          </>
        )}
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(ContentBlock01)
